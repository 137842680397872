import { useState } from "react";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import PropTypes from "prop-types";
import "react-day-picker/dist/style.css";
import "./calendar.css";
import "./style.css";

const pastMonth = new Date();
const Calendar = ({ clientId, methods }) => {
    const defaultSelected = {
        from: pastMonth,
        to: undefined,
    };
    const [range, setRange] = useState(defaultSelected);

    let footer = "Please pick the first day.";
    if (range?.from) {
        if (!range.to) {
            footer = format(range.from, "PPP");
        } else if (range.to) {
            footer = `${format(range.from, "PPP")}–${format(range.to, "PPP")}`;
        }
    }

    const handleResetClick = () => setRange(defaultSelected);

    const handleButtonClick = () => {
        // setTo(format(range.to, "yy-MM-dd"))
        // setFrom(format(range.from, "yy-MM-dd"))
        // setUser(clientId)
        methods.setTo(format(range.to, "yy-MM-dd"));
        methods.setFrom(format(range.from, "yy-MM-dd"));
        methods.setUser(clientId);
    };

    return (
        <div className='row'>
            <DayPicker
                mode='range'
                defaultMonth={pastMonth}
                selected={range}
                footer={footer}
                onSelect={setRange}
            />
            {range && range.from && range.to && (
                <div className='calendar__btn__container'>
                    <button
                        className='link'
                        onClick={handleButtonClick}>
                        Submit
                    </button>
                    {range.from && range.to && (
                        <button
                            className='link'
                            onClick={handleResetClick}>
                            Reset
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

Calendar.propTypes = {
    clientId: PropTypes.string.isRequired,
    methods: PropTypes.object.isRequired,
};

export default Calendar;
