import { useEffect, useState } from "react";
import Input from "./Input/input";
import "./loginForm.css";
import PropTypes from "prop-types";

const LoginForm = ({ handleSubmit, errorMessage }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        if (username.length > 4 && password.length > 4) {
            setSubmit(true);
        } else {
            setSubmit(false);
        }
    }, [username, password]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "username") {
            setUsername(value);
        } else {
            setPassword(value);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit(username, password);
    };

    return (
        <div className='loginForm__container'>
            <form>
                <div className='loginForm__form__container'>
                    <Input
                        name='username'
                        value={username}
                        handleInputChange={handleInputChange}
                    />
                    <Input
                        name='password'
                        value={password}
                        handleInputChange={handleInputChange}
                    />
                    <button
                        name='Login'
                        className='loginForm__btn__submit'
                        disabled={!submit}
                        onClick={handleFormSubmit}>
                        LOGIN
                    </button>
                    <p style={{ color: "#da1a35", fontWeight: "700" }}>
                        {errorMessage}
                    </p>
                </div>
            </form>
        </div>
    );
};

LoginForm.propTypes = {
    handleSubmit: PropTypes.func,
    errorMessage: PropTypes.element,
};

export default LoginForm;
