import CreateUserModal from "../Modals/CreateUser";
import ConfirmDeleteModal from "../Modals/ConfirmDelete";
import EditUserModal from "../Modals/EditUser";
import PropTypes from "prop-types";

const ModalsContainer = ({
    getUsers,
    users,
    setSelectedUser,
    setIsEditing,
    setShowDeleteModal,
    setShowCreateUserModal,
    selectedUser,
    showDeleteModal,
    isEditing,
    showCreateUserModal,
}) => {
    const handleClose = () => {
        setSelectedUser("");
        setIsEditing(false);
        setShowDeleteModal(false);
        setShowCreateUserModal(false);
    };

    return (
        <>
            <ConfirmDeleteModal
                open={showDeleteModal}
                selectedUser={selectedUser}
                getUsers={getUsers}
                handleClose={handleClose}
            />
            <EditUserModal
                open={isEditing}
                handleClose={handleClose}
                getUsers={getUsers}
                selectedUser={selectedUser}
                users={users}
            />
            <CreateUserModal
                open={showCreateUserModal}
                handleClose={handleClose}
                getUsers={getUsers}
                selectedUser={selectedUser}
                users={users}
            />
        </>
    );
};

ModalsContainer.propTypes = {
    getUsers: PropTypes.any,
    users: PropTypes.any,
    setSelectedUser: PropTypes.any,
    setIsEditing: PropTypes.any,
    setShowDeleteModal: PropTypes.any,
    setShowCreateUserModal: PropTypes.any,
    selectedUser: PropTypes.any,
    showDeleteModal: PropTypes.any,
    isEditing: PropTypes.any,
    showCreateUserModal: PropTypes.any,
};

export default ModalsContainer;
