import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { deleteUser } from "../../api";
import PropTypes from "prop-types";

const ConfirmDeleteModal = ({ open, handleClose, getUsers, selectedUser }) => {
    const handleDelete = async () => {
        if (selectedUser) {
            try {
                const res = await deleteUser(selectedUser);
                if (res.status === "success") {
                    await getUsers();
                    handleClose();
                } else {
                    window.alert("Something went wrong");
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>Delete User?</DialogTitle>
            <DialogContent>
                <div>Are you sure you want to delete this user?</div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color='primary'>
                    Cancel
                </Button>
                <Button
                    onClick={handleDelete}
                    color='secondary'>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmDeleteModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    getUsers: PropTypes.func,
    selectedUser: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
};

export default ConfirmDeleteModal;
