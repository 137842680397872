import "./input.css";
import PropTypes from "prop-types";

const Input = ({ name, value, handleInputChange }) => (
    <div className='input__div'>
        <input
            name={name}
            className='input'
            type={name === "password" ? "password" : "text"}
            placeholder={name}
            value={value}
            onChange={handleInputChange}
        />
    </div>
);

export default Input;
Input.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    handleInputChange: PropTypes.func,
};
