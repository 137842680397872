import "./ControlPanel.css";
import { useState } from "react";
import useData from "../../hooks/useData";
import { TableRow, TableCell } from "@mui/material";
import SettingsTable from "../SettingsTable";
import ModalsContainer from "../Modals/Container";
import PasswordDisplay from "../PasswordDisplay/PasswordDisplay";
import ActionButton from "../ActionButton/ActionButton";

const ControlPanel = () => {
    const { users, getUsers } = useData();
    const [isVisible, setIsVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const handleUserClick = (user) => {
        setSelectedUser(user);
    };

    const handleDeleteClick = (user) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
    };

    const openInput = (user) => {
        setSelectedUser(user);
        setIsEditing(true);
    };
    const openCreateUserModal = () => {
        setShowCreateUserModal(true);
    };

    return (
        <div className='controlPanel_container'>
            <h3 className='management_menu'>MANAGEMENT MENU</h3>
            <div className='controlPanel_settings'>
                <div
                    className='operations_option'
                    onClick={toggleVisibility}>
                    <h4>OPERATIONS</h4>
                </div>
                {isVisible && (
                    <SettingsTable
                        type='operations'
                        getUsers={getUsers}
                        users={users}
                        tableHeaders={[
                            "ClientID",
                            "Alias",
                            "Password",
                            "Actions",
                        ]}>
                        {users.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.clientId}</TableCell>
                                <TableCell>{row.alias}</TableCell>
                                <TableCell>
                                    <PasswordDisplay
                                        clientId={row.clientId}
                                        password={row.pass}
                                        isSelected={
                                            row.clientId === selectedUser
                                        }
                                        togglePasswordVisibility={
                                            handleUserClick
                                        }
                                    />
                                </TableCell>
                                <TableCell align='left'>
                                    <ActionButton
                                        textColor='white'
                                        action={() => openInput(row)}
                                        bgColor='teal'
                                        ml={-25}
                                        mr={0}
                                        muiColor='primary'
                                        text='Edit'
                                        minWidth={64}
                                    />
                                    {row.clientId !== "gameover" && (
                                        <ActionButton
                                            textColor='white'
                                            action={() =>
                                                handleDeleteClick(row)
                                            }
                                            bgColor='#da1a35'
                                            ml={30}
                                            mr={0}
                                            text='Delete'
                                            minWidth={64}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        <ModalsContainer
                            getUsers={getUsers}
                            users={users}
                            setSelectedUser={setSelectedUser}
                            setIsEditing={setIsEditing}
                            setShowDeleteModal={setShowDeleteModal}
                            setShowCreateUserModal={setShowCreateUserModal}
                            selectedUser={selectedUser}
                            showDeleteModal={showDeleteModal}
                            isEditing={isEditing}
                            showCreateUserModal={showCreateUserModal}
                        />
                        <div
                            className='create_user_modal'
                            onClick={openCreateUserModal}>
                            <p>CREATE USER</p>
                        </div>
                    </SettingsTable>
                )}
            </div>
        </div>
    );
};

export default ControlPanel;
