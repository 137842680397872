import "./dropdownMenu.css";
import PropTypes from "prop-types";
import useData from "../../../hooks/useData";

/**
 * @name ClientsSelector
 * @param {onClientSelect} param0
 */
const ClientsSelector = ({ onClientSelect }) => {
    const { getUsers, users } = useData();

    const handleFocus = () => {
        if (users.length === 0) {
            getUsers();
        }
    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue !== "0") {
            onClientSelect(selectedValue);
        }
    };

    return (
        <div className='dropdown-box'>
            <select
                className='dropdown-menu'
                onFocus={handleFocus}
                onChange={handleSelectChange}>
                <option value={0}>Select Calendar</option>
                {users.map((user, index) => (
                    <option
                        key={index}
                        value={user.clientId}>
                        {user.alias}
                    </option>
                ))}
            </select>
        </div>
    );
};

ClientsSelector.propTypes = {
    onClientSelect: PropTypes.func.isRequired,
};

export default ClientsSelector;
