import { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { updateUser } from "../../api";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { handleFieldChange } from "../../helpers/EditUser.helpers";

const EditUserModal = ({
    open,
    handleClose,
    getUsers,
    selectedUser,
    users,
}) => {
    const [newClientId, setNewClientId] = useState(undefined);
    const [newAlias, setNewAlias] = useState(undefined);
    const [newPassword, setNewPassword] = useState(undefined);
    const [clientIdError, setClientIdError] = useState("");
    const [aliasError, setAliasError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [shouldShowPassword, setShouldShowPassword] = useState(false);

    const handleSaveClick = async () => {
        if (!clientIdError && !aliasError && !passwordError && selectedUser) {
            try {
                const res = await updateUser(
                    selectedUser,
                    newClientId,
                    newAlias,
                    newPassword
                );

                if (res.status === "success") {
                    handleClose();
                    await getUsers();
                } else {
                    window.alert("Something went wrong");
                }
            } catch (err) {
                console.log(`An Error Occurred : ${err}`);
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>Edit User</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    placeholder={selectedUser.clientId}
                    id='clientId'
                    label='ClientID'
                    type='text'
                    value={newClientId}
                    onChange={(event) =>
                        handleFieldChange(
                            "edit",
                            event,
                            "clientId",
                            setNewClientId,
                            setClientIdError,
                            selectedUser,
                            users
                        )
                    }
                    fullWidth
                    error={!!clientIdError}
                    helperText={clientIdError}
                />
                <TextField
                    autoFocus
                    placeholder={selectedUser.alias}
                    margin='dense'
                    id='alias'
                    label='Alias'
                    type='text'
                    value={newAlias}
                    onChange={(event) =>
                        handleFieldChange(
                            "edit",
                            event,
                            "alias",
                            setNewAlias,
                            setAliasError,
                            selectedUser,
                            users
                        )
                    }
                    fullWidth
                    error={!!aliasError}
                    helperText={aliasError}
                />
                <div className='password_text_input'>
                    <TextField
                        type={shouldShowPassword ? "password" : "text"}
                        autoFocus
                        margin='dense'
                        label='Password'
                        placeholder={
                            shouldShowPassword ? "" : selectedUser.pass
                        }
                        id='password'
                        value={newPassword}
                        onChange={(event) =>
                            handleFieldChange(
                                "edit",
                                event,
                                "pass",
                                setNewPassword,
                                setPasswordError,
                                selectedUser,
                                users
                            )
                        }
                        fullWidth
                        error={!!passwordError}
                        helperText={passwordError}
                    />
                    {shouldShowPassword ? (
                        <VisibilityOffIcon
                            onClick={() =>
                                setShouldShowPassword(!shouldShowPassword)
                            }
                        />
                    ) : (
                        <VisibilityIcon
                            onClick={() =>
                                setShouldShowPassword(!shouldShowPassword)
                            }
                        />
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color='secondary'>
                    Cancel
                </Button>
                <Button
                    onClick={handleSaveClick}
                    color='primary'
                    disabled={clientIdError || aliasError || passwordError}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditUserModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    getUsers: PropTypes.func,
    showPassword: PropTypes.bool,
    selectedUser: PropTypes.object,
    users: PropTypes.any,
};

export default EditUserModal;
