import Logo from "../../assets/cubeLogo.svg";
import "./navBar.css";
import PropTypes from "prop-types";

const NavBar = ({
    isLogOutShown,
    handleLogout,
    isControlPanelOpen,
    setIsControlPanelOpen,
}) => {
    const handleClick = () => {
        handleLogout();
    };
    const handlePanelClick = () => {
        setIsControlPanelOpen(!isControlPanelOpen);
    };

    const { isAdminLoggedIn, cubeCounterUser } = sessionStorage;

    return (
        <nav className='navBar'>
            <div>
                <img
                    className='logo'
                    src={Logo}
                    alt='logo'
                />
            </div>
            <h1 className='text'>
                Welcome
                <text
                    style={{
                        color: isLogOutShown ? "green" : "#da1a35",
                        marginLeft: "5%",
                    }}>
                    {`${isAdminLoggedIn ? cubeCounterUser : "CUBEADMIN"}!`}
                </text>
            </h1>
            {isLogOutShown ? (
                <button
                    className='signIn'
                    onClick={handleClick}>
                    Log Out
                </button>
            ) : (
                ""
            )}
            {isLogOutShown && isAdminLoggedIn === "true" && (
                <button
                    className='admin_panel'
                    style={{
                        backgroundColor: isControlPanelOpen
                            ? "#696a6c"
                            : "green",
                    }}
                    onClick={handlePanelClick}>
                    {`${isControlPanelOpen ? "Admin" : "Control"} Panel`}
                </button>
            )}
        </nav>
    );
};

NavBar.propTypes = {
    isLogOutShown: PropTypes.bool,
    handleLogout: PropTypes.func,
    isControlPanelOpen: PropTypes.bool,
    setIsControlPanelOpen: PropTypes.any,
    user: PropTypes.any,
};

export default NavBar;
