import PropTypes from "prop-types";
import { Button } from "@mui/material";

const ActionButton = ({
    action,
    bgColor,
    ml,
    text,
    muiColor,
    textColor,
    minWidth,
    mr,
}) => (
    <Button
        onClick={action}
        style={{
            color: textColor ? textColor : "white",
            backgroundColor: bgColor,
            margin: `0% ${mr}% 0% ${ml}%`,
            minWidth: `${minWidth}px`,
        }}
        variant='contained'
        color={muiColor}>
        {text}
    </Button>
);

ActionButton.propTypes = {
    action: PropTypes.func.isRequired,
    bgColor: PropTypes.string,
    ml: PropTypes.number,
    text: PropTypes.string.isRequired,
    muiColor: PropTypes.string,
    textColor: PropTypes.string,
    minWidth: PropTypes.number,
    mr: PropTypes.number,
};

export default ActionButton;
