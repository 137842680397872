import NavBar from "../NavBar";
import LoginFrom from "../LoginForm/LoginForm";
import Main from "../Main";
import useData from "../../hooks/useData";
import { useEffect, useState } from "react";
import { createParticlesBackDrop } from "cube-pts-pack";
import "./background.css";

const Layout = () => {
    const { errorMessage, isLoggedIn, handleLogin, handleLogout } = useData();
    const [isControlPanelOpen, setIsControlPanelOpen] = useState(false);
    const user = sessionStorage.getItem("cubeCounterUser");
    useEffect(() => {
        sessionStorage.setItem("isLoggedIn", isLoggedIn);
    }, [isLoggedIn]);

    useEffect(() => {
        createParticlesBackDrop(
            {
                connectionThreshold: 200,
                particlesNumber: 50,
                particlesSpeed: 1,
            },
            () => {}
        );
    }, []);

    return (
        <>
            <NavBar
                isLogOutShown={isLoggedIn}
                handleLogout={handleLogout}
                isControlPanelOpen={isControlPanelOpen}
                setIsControlPanelOpen={setIsControlPanelOpen}
            />
            {isLoggedIn ? (
                <Main
                    isControlPanelOpen={isControlPanelOpen}
                    user={user}
                />
            ) : (
                <LoginFrom
                    handleSubmit={handleLogin}
                    errorMessage={errorMessage}
                />
            )}
        </>
    );
};

export default Layout;
