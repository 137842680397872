/**
 * Handles the change of a field value, performs validations, and updates state accordingly.
 *
 * @param {string} purpose - The purpose of the operation.
 * @param {Object} event - The event object triggered by the change.
 * @param {string} field - The field being modified.
 * @param {function} setNewValue - A function to set the new value of the field.
 * @param {function} setError - A function to set error messages.
 * @param {Object} selectedUser - The selected user object.
 * @param {Array<Object>} users - An array of user objects for validation checks.
 * @returns - A Promise that resolves after handling the field change.
 */

export const handleFieldChange = async (
    purpose,
    event,
    field,
    setNewValue,
    setError,
    selectedUser,
    users
) => {
    const newValue = event.target.value;
    setNewValue(newValue);
    if (newValue === "") {
        setError("");
    } else if (newValue.length < 5) {
        setError(`${field} must be at least 5 characters long`);
    } else if (newValue.length > 15) {
        setError(`${field} shouldn't contain more than 15 characters`);
    } else if (newValue.includes(" ")) {
        setError(`${field} shouldn't contain spaces`);
    } else if (users.some((user) => user[field] === newValue)) {
        setError(`${field} already exists`);
    } else if (purpose === "edit" && newValue === selectedUser[field]) {
        setError(`${field} is the same as the old one`);
    } else {
        setError("");
    }
};
